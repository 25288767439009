<template>
  <div>
    <div class="viewMap" v-if="viewMap">
      <div class="map">
        <Map ref="map"></Map>
      </div>

      <div class="choose">
        <div class="block">
          <div class="set" @click="openRandom" v-bind:class="{ red: !mapStart }">随机坐标</div>
          <div class="set" @click="openFixed" v-bind:class="{ red: mapStart }">指定坐标</div>
        </div>
        <div class="refresh" v-if="!mapStart" @click="Randomref"></div>
        <div class="reset" v-if="mapStart" @click="reset"></div>
      </div>
      <div class="mapinfo">
        <div class="block" @click="openFixedX">
          <span>X</span>
          <p>{{ mapNum.x ? mapNum.x : '选择坐标' }}</p>
        </div>
        <div class="block" v-if="mapNum.x||mapNum.x=='0'" @click="openFixedY">
          <span>Y</span>
          <p>{{ mapNum.y ? mapNum.y : '选择坐标' }}</p>
        </div>

      </div>
      <div class="uploaderMain">
        <van-uploader :after-read="afterRead" class="uploader"/>
        <img :src="contentImage" class="contentImage" v-if="contentImage"/>
        <div class="ok" @click="getCreate">提交作品</div>
      </div>
    </div>

    <div class="PickerX" v-if="pickerX">
      <van-picker
          class="main"
          title="请选择X坐标"
          show-toolbar
          :columns="columnsX"
          @confirm="onConfirmX"
          @cancel="closecancelX"
      />
    </div>
    <div class="PickerX" v-if="pickerY">
      <van-picker
          class="main"
          title="请选择Y坐标"
          show-toolbar
          :columns="columnsY"
          @confirm="onConfirmY"
          @cancel="closecancelY"
      />
    </div>
    <div class="penName" v-if="penNameStart">
      <div class="main">
        <div class="title">请输入作品名称</div>
        <input type="text" v-model="penName">
        <div class="ok" @click="setName">完成</div>
        <div class="close" @click="closeName">返回</div>
        <div class="f">注：作品名称不超过10个字数</div>
      </div>
    </div>
    <div class="prize" v-if="prizeimg">
      <div class="title">恭喜你中奖了</div>
      <div class="img">
        <img :src="prizeimg" alt="">
      </div>
      <div class="close" @click="close">关闭</div>
    </div>
    <Load ref="load"></Load>
    <div class="LoadMain" v-if="LoadMain">
      <div class="text">
        <p>上传中</p>
        <p>{{ LoadTage }}</p>
        <div class="link" :style="{width:LoadTage}"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {Dialog, Notify, Picker, Uploader} from "vant";
import Load from "../../../components/load";
import Map from './mapBg.vue'
import {fixed, putWork, random} from "@/api/introduce";

const Aliyun = {
  accessKeyId: "LTAI4GGraGrb3vc6sDGXMSya",
  accessKeySecret: 'TVmlizrLbGrQdt5jgQj3UyabXIKjZd',
  bucket: '3qlab-wrth',
  region: 'oss-cn-hangzhou',
  url: 'https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/'
}
//阿里云SDK
const OSS = require('ali-oss');
const client = OSS({
  accessKeyId: Aliyun.accessKeyId,
  accessKeySecret: Aliyun.accessKeySecret,
  bucket: Aliyun.bucket,
  region: Aliyun.region,
  secure: true
});


export default {
  data() {
    return {
      userInfo: {},

      //视图
      viewMap: true,
      mapStart: true,
      pickerX: false,
      pickerY: false,
      LoadMain: false,
      penNameStart: '',

      LoadTage: '0%',

      //坐标数据
      columnsX: [],
      columnsY: [],
      //作品数据
      WorkData: [],

      CanvasNumberX: '',
      CanvasNumberY: '',

      mapNum: {
        x: null,
        y: null,
      },
      contentImage: '',
      //作品昵称
      penName: '',
      prizeimg: ''
    }
  },
  mounted() {
    this.init()
  },
  components: {
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    Map,
    Load
  },
  methods: {
    close() {
      this.prizeimg = ''
      this.$router.push('/exhibit')
    },
    closeName(){
      this.penNameStart=false
      this.penName=''
    },
    setName() {
      let PenName = this.penName
      if (!PenName) {
        Dialog.alert({
          message: '请输入作品昵称',
        });
        return;
      }
      if (PenName.length >= 11) {
        Dialog.alert({
          message: '作品名称超出10个字数',
        });
        return;
      }

      let ActivityInfo = this.ActivityInfo
      let ActivityID = ActivityInfo.id
      let TitleNumber = ActivityInfo.TitleNumber
      let X = this.mapNum.x
      let Y = this.mapNum.y
      let WorkNumber = TitleNumber + 'X' + X + 'Y' + Y
      let CompanyID = localStorage.getItem("CompanyID")
      let UserID = this.userInfo.id

      let contentImage = this.contentImage

      this.$refs.load.open()

      putWork({UserID, CompanyID, PenName, ActivityID, WorkNumber, ImageUrl: contentImage, X, Y}).then(res => {
        this.$refs.load.close()
        if (res.errorCode == 200) {
          let payload = res.payload
          if (payload.Prize == '中奖') {
            Notify({type: 'success', message: '恭喜你踩中了彩蛋'});
            let prizeimg = payload.Image
            this.prizeimg = prizeimg
          } else {
            Notify({type: 'success', message: '提交成功'});
            if (payload.InShow == '未审核') {
              this.$parent.openPrompt()
            } else {
              this.$router.push('/exhibit')
            }
          }
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      }).catch(() => {
        this.$refs.load.close()
        this.penNameStart = false
        this.penName = ''
        Notify({type: 'warning', message: '提交失败,请重新提交'});
      })
    },
    //上传图片
    async afterRead(value) {
      let that = this
      let fileData = value.file
      let ImgTime = this.ImgTime()
      let filename = this.ImgTimestamp()
      this.LoadMain = true
      this.LoadTage = '0%'
      let result = await client.multipartUpload(`image/${ImgTime}/${filename}.jpg`, fileData, {
        progress(e) {
          that.Tprogress(e)
        }
      });
      let res = result.res

      if (res.status == 200 && res.statusCode == 200) {
        this.LoadMain = false
        let requestUrls = this.removeURLParams(res.requestUrls[0])
        this.contentImage = requestUrls
        Notify({type: 'success', message: '上传成功'});
      } else {
        this.LoadMain = false
        Notify({type: 'warning', message: '上传失败'});
      }
    },
    Tprogress(p) {
      let percentage = (p * 100).toFixed(2) + "%";
      this.LoadTage = percentage
    },
    removeURLParams(url) {
      let routeData = url.split("?")[0];
      return routeData
    },
    reset() {
      this.mapNum.x = null
      this.mapNum.y = null
      this.$refs.map.clear()
    },
    closecancelX() {
      this.pickerX = false
    },
    closecancelY() {
      this.pickerY = false
    },
    openFixedY() {
      if (this.mapStart == false) {
        return
      }
      this.pickerY = true
    },
    //坐标初始化
    openFixedX() {
      if (this.mapStart == false) {
        return
      }
      this.pickerX = true
      let CanvasNumberX = this.CanvasNumberX
      let CanvasNumberY = this.CanvasNumberY
      let ColumnsX = []
      let WorkData = this.WorkData
      for (let i = 1; i <= CanvasNumberX; i++) {
        let number = JSON.stringify(JSON.parse(CanvasNumberY))
        for (let k in WorkData) {
          if (WorkData[k].X == i && WorkData[k].InShow == '已占用') {
            number--
          }
        }
        ColumnsX.push(i + ' (可用:' + (number) + '个)')
      }

      this.columnsX = ColumnsX
    },
    initcolumnsY() {
      this.load = false

      let ColumnsY = []
      let x = this.mapNum.x

      let AddData = []
      let WorkData = this.WorkData
      for (let i in WorkData) {
        if (WorkData[i].X == x) {
          AddData.push(WorkData[i])
        }
      }

      let CanvasNumberY = this.CanvasNumberY

      for (let i = 0; i < CanvasNumberY; i++) {
        let text = (i + 1) + '(可用)'
        for (let k in AddData) {
          if (AddData[k].Y == (i + 1) && AddData[k].InShow == '已占用') {
            text = (i + 1) + '(不可用)'
          }
        }
        ColumnsY.push(text)
      }
      this.columnsY = ColumnsY
    },
    onConfirmX(value) {
      this.pickerX = false

      let str = value;
      let newStr = str.replace(/\(.*?\)/g, '');
      newStr = Number(newStr)

      this.mapNum.x = newStr
      this.mapNum.y = null
      this.initcolumnsY()

    },
    onConfirmY(value) {
      let regex = /\((.+?)\)/g;
      let options = value.match(regex)
      if (options[0] == '(可用)') {
        this.pickerY = false
        let str = value;
        let newStr = str.replace(/\(.*?\)/g, '');
        newStr = Number(newStr)
        this.mapNum.y = newStr
        this.$refs.map.highlights(this.mapNum)
      } else {
        Dialog.alert({
          message: '很抱歉，当前位置已占用',
        }).then(() => {
          // on close
        });
      }
    },
    init() {
      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      this.ActivityInfo = ActivityInfo
      let userInfo = JSON.parse(localStorage.getItem("user"))
      this.userInfo = userInfo
      this.openFixed()

      this.openRandom()
    },
    Randomref() {
      this.openRandom()
    },
    //随机坐标
    openRandom() {
      this.$refs.load.open()
      this.mapStart = false
      let ActivityID = this.ActivityInfo.id
      random({ActivityID}).then(res => {
        this.$refs.load.close()
        if (res.errorCode == 200) {
          let payload = res.payload
          let x = payload.X
          let y = payload.Y
          this.mapNum.x = x
          this.mapNum.y = y
          this.$refs.map.highlights(this.mapNum)
          Notify({type: 'success', message: '获取坐标成功'});
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }

      })
    },
    //指定坐标
    openFixed() {
      if (this.mapStart == false) {
        this.$refs.map.clear()
        this.mapStart = true
        this.mapNum.x = null
        this.mapNum.y = null
      }
      let ActivityID = this.ActivityInfo.id

      fixed({ActivityID}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          let CanvasNumberX = payload.CanvasNumberX
          let CanvasNumberY = payload.CanvasNumberY
          this.CanvasNumberX = CanvasNumberX
          this.CanvasNumberY = CanvasNumberY
          this.WorkData = payload.WorkData
        } else {
          Notify({type: 'warning', message: res.errorMsg});
        }
      })
    },
    getCreate() {
      let contentImage = this.contentImage
      if (!contentImage) {
        Dialog.alert({
          message: '图片未上传',
        });
        return;
      }
      let x = this.mapNum.x
      let y = this.mapNum.y
      if (!x && !y) {
        Dialog.alert({
          message: '未选择作品位置',
        });
        return;
      }

      this.penNameStart = true
      this.penName = ''
    },
    ImgTimestamp() {
      var timestamp = Date.now(); // 获取当前时间戳（单位为毫秒）
      return timestamp
    },
    ImgTime() {
      let Year = new Date().getFullYear();  //年份
      let Month = new Date().getMonth() + 1;  //月份
      let Day = new Date().getDate();  //天
      let time = `${Year}-${Month}-${Day}`;

      return time;
    }
  }
}
</script>

<style scoped lang="scss">


.PickerX {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;

  .main {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.enars {
  width: 90%;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  margin-top: 10px;

  .enlarge {
    background-size: 100% 100%;
    width: 15px;
    height: 15px;
    background-image: url("../../../assets/image/map/enlarge.png");
    display: inline-block;
  }

  .narrow {
    background-size: 100% 2px;
    background-position: center center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    background-image: url("../../../assets/image/map/narrow.png");
    display: inline-block;
    margin-left: 15px;
  }

  span {
    font-size: 14px;
    margin-left: 20px;
  }

  .data {
    font-size: 14px;
    display: inline;
    margin-left: 15px;

  }
}


.openmap {
  background-color: #973733;
  color: white;
  text-align: center;
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.load {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;

  .ico {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -15px;
    background-size: 100% 100%;
    background-image: url("../../../assets/image/map/load.png");
    transition: 0.5s;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.map {
  width: 100%;
  height: 250px;
  overflow: hidden;
  white-space: nowrap;
  position: relative;

  //background-color: rgba(114, 6, 13, 0.81);
}

.choose {
  width: 100%;
  height: 50px;
  margin-top: 10px;

  .block {
    width: 180px;
    height: 35px;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    margin-left: 20px;
    display: flex;
    float: left;


    .set {
      width: 50%;
      height: 100%;
      text-align: center;
      font-size: 14px;
      line-height: 35px;
      cursor: pointer;
      user-select: none;
    }

    .i {
      //border-right: 1px solid #000;
    }

    .red {
      background-color: #800354;
      color: #ffffff;
    }
  }

  .refresh {
    width: 35px;
    height: 35px;
    background-size: 25px 25px;
    background-image: url("../../../assets/image/map/refresh.png");
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 20px;
    //display: inline-block;
    float: left;
    cursor: pointer;
    user-select: none;
  }


  .reset {
    width: 35px;
    height: 35px;
    background-size: 25px 25px;
    background-image: url("../../../assets/image/map/reset.png");
    background-position: center center;
    background-repeat: no-repeat;
    margin-left: 20px;
    //display: inline-block;
    float: left;
    cursor: pointer;
    user-select: none;
  }
}

.mapinfo {
  width: 100%;
  height: 60px;
  text-indent: 20px;
  margin-top: 10px;
  position: relative;

  .block {
    display: inline-block;
    width: 100px;
    height: 35px;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    margin-right: 20px;
    text-indent: 0;
    position: relative;

    span {
      width: 35px;
      height: 35px;
      display: inline-block;
      //color: rgba(159, 159, 159, 0.5);
      text-align: center;
      line-height: 35px;
      font-size: 14px;
      position: absolute;
      left: 0;
    }

    p {
      position: absolute;
      left: 40px;
      height: 35px;
      top: 0;
      line-height: 35px;
      margin: 0;
    }

    input {
      outline: none;
      text-indent: 1rem;
      width: 114px;
      height: 100%;
      font-size: 14px;
      color: #666;
      border: none;
      position: absolute;
      left: 35px;
      padding: 0;
      background-color: #fff;
    }
  }
}


.uploaderMain {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 50px;

  .contentImage {
    display: block;
    width: 150px;
    height: 250px;
    margin-left: 20px;
  }

  .uploader {
    display: block;
    margin-left: 20px;
  }

  .ok {
    width: 367px;
    height: 44px;
    background-color: #800354;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 44px;
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
    user-select: none;
    display: block;
  }
}


.penName {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .main {
    height: 180px;
    width: 300px;
    background-color: rgba(249, 249, 249, 0.97);
    box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -105px;

    .title {
      width: 100%;
      height: 30px;
      font-size: 13px;
      text-align: center;
      //color: #898989;
      line-height: 30px;
    }

    input {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      border: none;
      width: 250px;
      height: 40px;
      outline: none;
      font-size: 14px;
      text-align: center;
    }

    .ok {
      width: 120px;
      height: 35px;
      line-height: 35px;
      background-color: #8c0c63;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      left: 20px;
      top: 100px;
    }

    .close{
      width: 120px;
      height: 35px;
      line-height: 35px;
      background-color: #8c0c63;
      color: #FFFFFF;
      text-align: center;
      position: absolute;
      left: 160px;
      top: 100px;
    }

    .reset {
      background-image: url("../../../assets/image/map/reset.png");
      background-size: 100% 100%;
      position: absolute;
      right: 10px;
      top: 5px;
      width: 15px;
      height: 15px;
    }

    .f {
      margin-top: 8px;
      font-size: 12px;
      color: #898989;
      text-indent: 1rem;
      position: absolute;
      left: 10px;
      top: 140px;
    }

  }

}

.prize {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;

  .title {
    background-color: #4b103b;
    color: #FFFFFF;
    font-size: 25px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
  }

  .img {
    width: 300px;
    height: 450px;
    position: absolute;
    top: 90px;
    background-color: #FFFFFF;
    left: 50%;
    margin-left: -150px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .close {
    background-color: #4b103b;
    color: #FFFFFF;
    font-size: 17px;
    text-align: center;
    width: 100px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    position: absolute;
    top: 580px;
    left: 50%;
    margin-left: -50px;
  }
}

.LoadMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .text {
    position: absolute;
    top: 50%;
    margin-top: -44px;
    color: #fff;
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 25px;
    font-size: 14px;

    .link {
      background-color: #1989fa;
      height: 10px;
    }
  }
}
</style>
