<template>
  <div v-if="view" class="Prompt">
    <div class="main">
      <h2>温馨提示</h2>
      <div class="data">{{ data }}</div>
      <div class="close" @click="close">关闭</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      view: false,
      data: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const aa = "2024-2-9 07:00:00";  // 过期时间
      const date = new Date(aa);
      console.log(date)
      // if(date.getTime() < Date.now()){
      //   this.data='出于上传内容需要人工审核这一方面的考虑，本次活动在除夕及春节期间（2.9-2.17）将无法做到实时更新，而是集中时段统一审核、更新内容，期间您仍可使用上传功能对照片进行上传，感谢您的支持与谅解。'
      // }else{
      //   this.data='感谢您对本活动的支持！您的作品将在审核通过后更新在主界面上，预计需要1分钟左右，您可以在审核完成后转发作品及本活动页面，集赞赢取丰厚奖品，更有机会踩中随机彩蛋。'
      // }
      this.data = '您的作品将在审核通过后更新在主界面上，预计需要1分钟左右，感谢您对本活动的支持！'
    },
    open() {
      this.view = this
    },
    close() {
      this.$router.push('/exhibit')
    }
  }
}
</script>

<style scoped lang="scss">
.Prompt {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;

  .main {
    background-color: #7b0708;
    color: #FFFFFF;
    width: 280px;
    height: 560px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -140px;
    margin-top: -280px;

    h2 {
      text-align: center;
      position: absolute;
      top: 20px;
      width: 100%;
    }

    .data {
      position: absolute;
      top: 80px;
      width: 87%;
      left: 19px;
      font-size: 16px;
      line-height: 35px;
    }

    .close {
      position: absolute;
      height: 35px;
      width: 100px;
      left: 50%;
      bottom: 80px;
      border-radius: 7px;
      margin-left: -50px;
      text-align: center;
      line-height: 35px;
      font-size: 13px;
      border: 4px solid #fff;
      color: #fff;
      transition: 0.5s all
    }
  }
}
</style>