<template>
  <div class="Header" ref="Header">
    <div class="menu" @click="openexhibit" v-if="menu">
      <img :src="ReturnImg" v-if="ReturnImg" alt="">
    </div>
    <div class="pencil" @click="openmap" v-if="pencil">
      <span v-if="pencilStart"></span>
      <img :src="MapImg" v-if="MapImg" alt="" style="width: 100%;height: 100%">
    </div>
    <div class="logo"></div>
    <div class="avatar" @click="openUser" v-if="avatar">
      <img :src="UserImg" v-if="UserImg" alt="" style="width: 100%;height: 100%">
    </div>
  </div>
</template>

<script>


import {iconInit} from "@/api/exhibit";

export default {
  data() {
    return {
      avatar: false,
      pencil: false,
      menu: false,
      pencilStart: false,

      ReturnImg: null,
      UserImg: null,
      MapImg: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {

      let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
      this.ActivityInfo = ActivityInfo
      let ActivityID = ActivityInfo.id


      iconInit({ActivityID}).then(res => {
        if (res.errorCode == 200) {
          let payload = res.payload
          //返回按钮
          this.ReturnImg = payload.ReturnImg
          //个人中心
          this.UserImg = payload.UserImg
          //认领坐标
          this.MapImg = payload.MapImg
        }
      })

      this.logoStarts = setInterval(() => {
        if (this.pencilStart == true) {
          this.pencilStart = false
        } else {
          this.pencilStart = true
        }
      }, 500)

      this.upRouter()
    },
    upRouter() {
      switch (this.$route.path) {
        case "/user":
          this.menu = true
          this.lamp = true
          break;
        case "/list":
          this.avatar = true
          this.pencil = true
          break;
        case "/map":
          this.menu = true
          this.avatar = true
          break;
        case "/exhibit":
          this.avatar = true
          this.pencil = true
          this.lamp = true
          this.ai = true
          break;
        case "/introduce":
          this.menu = true
          this.avatar = true
          this.lamp = true
          break;
        case "/lamp":
          this.menu = true
          this.avatar = true
          break;
      }
    },

    openexhibit() {
      this.$router.push('/exhibit')
    },
    openUser() {
      this.$router.push('/user')
    },
    openmap() {
      this.$store.state.paintingStart = false
      this.$router.push('/introduce')
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

.Header {
  width: 100%;
  height: 60px;
  position: relative;
  background-color: #FFFFFF;


  .lamp {
    width: 27px;
    height: 22px;
    position: absolute;
    right: 50px;
    top: 50%;
    margin-top: -11px;
    background-color: #bababa;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .MaxData {
      position: absolute;
      width: 100%;
      height: 0;
      left: 0;
      bottom: 0;
      background-color: #f55958;
    }
  }

  .menu {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -25px;
    background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .pencil {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -25px;
    //background-image: url("https://hz-mayi.nos-eastchina1.126.net/wrtx/feather.png");
    background-size: 100% 100%;

    span {
      width: 10px;
      height: 10px;
      background-color: #d80000;
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      border-radius: 50px;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .logo {
    width: 180px;
    height: 50px;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    top: 50%;
    margin-top: -25px;
    background-image: url("https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/image/2024-5-23/%E5%A4%A9%E9%95%BF%E5%85%A8%E5%AE%B6%E7%A6%8F.png");
    background-size: 100% 100%;
  }

  .avatar {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -25px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

}
</style>
