<template>
  <div class="Map">
    <img :src="ActivityInfo.BgImg" v-if="ActivityInfo.BgImg">
    <div class="inX" :style="x" v-if="x"></div>
    <div class="inY" :style="y" v-if="y"></div>
    <div class="blocks" :style="inpos" v-if="inpos"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ActivityInfo:{},
      x: '',
      y: '',
      inpos: '',
    }
  },
  mounted() {
    let ActivityInfo = JSON.parse(localStorage.getItem("ActivityInfo"))
    this.ActivityInfo = ActivityInfo
  },
  methods: {
    clear() {
      this.x = ''
      this.y = ''
      this.inpos = ''
    },
    highlights(str) {
      let TolSizeX = this.ActivityInfo.CanvasNumberX
      let TolSizeY = this.ActivityInfo.CanvasNumberY
      let x = str.x / TolSizeX
      let y = str.y / TolSizeY

      let sumx = Number(x * 100).toFixed(1);
      if (sumx == 0) {
        sumx = 1
      }
      sumx += "%";
      let sumy = Number(y * 100).toFixed(1);
      if (sumy == 0) {
        sumy = 1
      }
      sumy += "%";

      this.x = 'top:' + sumy
      this.y = 'left:' + sumx

      let inpos = `left:${sumx};top:${sumy};display:block;`
      this.inpos = inpos
    },
  }
}
</script>

<style scoped lang="scss">
.Map {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .inX {
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 10px;
  }

  .inY {
    width: 1px;
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    left: 10px;
    top: 0
  }

  .block {
    width: 25px;
    height: 25px;
    border: 2px solid #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    margin-left: -14.5px;
    margin-top: -14.5px;
  }

  .blocks {
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    margin-left: -4.5px;
    margin-top: -4.5px;
  }
}
</style>
