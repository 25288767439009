import request from "../utils/request";

//头部icon初始化
export const iconInit = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/icon/init",
        method: "get",
        params: query,
    });
};

export const WorkFindById = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/findworkbyid",
        method: "get",
        params: query,
    });
};

//查询活动基本信息
export const ActivityFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/Activity/find",
        method: "get",
        params: query,
    });
};

//查询作品列表
export const WorkFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/find",
        method: "get",
        params: query,
    });
};

//点赞&取消点赞
export const setkork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/collect",
        method: "post",
        data: query,
    });
};



//排行榜 前30
export const popularFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/popular",
        method: "get",
        params: query
    });
};

export const prizeRankingFind = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/ranking/prize",
        method: "get",
        params: query
    });
};

//查询单个作品基本数据
export const workFindID = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/findID",
        method: "get",
        params: query
    });
};

//图片转换base64
export const base64Img = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/base64Img",
        method: "post",
        data: query,
    });
};
//二维码
export const base64QR = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/qr",
        method: "post",
        data: query,
    });
};


//查询弹幕（前100条）
export const findComment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/barrage/find",
        method: "get",
        params: query,
    });
};

//发送弹幕
export const addComment = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/barrage/add",
        method: "post",
        data: query,
    });
};

//搜索
export const findSearch = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/work/search",
        method: "get",
        params: query,
    });
};

//查询作品评论
export const findEvaluate = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/evaluate/find",
        method: "get",
        params: query,
    });
};

//添加作品评论
export const addEvaluate = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Exhibit/evaluate/add",
        method: "post",
        data: query,
    });
};



