<template>
  <div class="introduce">
    <Header class="Header" ref="Header"></Header>
    <div class="div" :style="listheight">
      <div class="main">
        <mapMain ref="mapMain"></mapMain>
      </div>
    </div>
    <Prompt ref="Prompt"></Prompt>
  </div>
</template>

<script>
import Header from "../../components/Header";
import mapMain from "./com/map.vue";
import Prompt from './com/Prompt.vue'

export default {
  data() {
    return {
      TolNumber: true,
      listheight: ''
    }
  },
  components: {
    Header,
    mapMain,
    Prompt
  },
  mounted() {
    let listheight = document.documentElement.clientHeight - 55
    listheight = 'height:' + listheight + 'px'
    this.listheight = listheight
  },
  methods: {
    openPrompt() {
      this.$refs.Prompt.open()
    }
  }
}
</script>

<style scoped lang="scss">
.introduce {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #acacac;

  .Header {
    height: 55px;
    //margin-bottom: 20px;
  }

  .div {
    width: 100%;
    overflow: auto;
    overflow-x: hidden;

    .main {
      //scroll-snap-align: center;
      width: 100%;
      height: 100%;
    }
  }

  .div::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  .div::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 0;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #535353;
  }

  .div::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: #ededed;
  }

  .text {
    width: 90%;
    margin: 0 auto;
    line-height: 22px;

    span {
      color: #A42E2E;
    }
  }

  .center {
    width: 280px;
    text-align: right;
    margin-top: 15px;

    span {
      color: #A42E2E;
    }
  }

  .red {
    margin-top: 15px;
    color: #A42E2E;
  }

  .next {
    width: 100%;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    background-image: url("../../assets/image/map/next.png");
    margin-top: 30px;
  }

  .fs {
    text-align: center;
    font-size: 12px;
    color: #666666;
    margin-top: 10px;
  }

  .text4 {
    color: #A42E2E;
    text-align: center;
    margin-top: 15px;
  }
}
</style>
