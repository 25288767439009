import request from "../utils/request";

//认领随机坐标
export const random = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Introduce/random",
        method: "get",
        params: query,
    });
};

//查询已经启用画布与已认领坐标数据
export const fixed = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Introduce/fixed",
        method: "get",
        params: query,
    });
};




//提交新作品
export const putWork = (query) => {
    return request({
        url: process.env.VUE_APP_URL + "website/Introduce/putwork",
        method: "post",
        data: query,
    });
};

